interface PendingPromise<T> {
  promise: Promise<T>;
  resolve: (value: T | PromiseLike<T>) => void;
  reject: (reason?: any) => void;
}

export class PromiseSource<T> {
  private _promise: PendingPromise<T> | undefined;
  private _result: PromiseSettledResult<T> | undefined;

  public resolve(value: T): void {
    if (this._promise) {
      try {
        return this._promise.resolve(value);
      } finally {
        this._promise = undefined;
      }
    }

    if (this._result?.status === 'rejected') {
      return;
    }

    this._result = { status: 'fulfilled', value };
  }

  public reject(reason: any): void {
    if (this._promise) {
      try {
        return this._promise.reject(reason);
      } finally {
        this._promise = undefined;
      }
    }

    this._result = { status: 'rejected', reason };
  }

  public result(): PromiseSettledResult<T> | undefined {
    try {
      return this._result;
    } finally {
      this._result = undefined;
    }
  }

  public next(): Promise<T> {
    if (this._result) {
      try {
        if (this._result.status === 'rejected') {
          return Promise.reject(this._result.reason);
        } else {
          return Promise.resolve(this._result.value);
        }
      } finally {
        this._result = undefined;
      }
    }

    if (!this._promise) {
      let fns: Omit<PendingPromise<T>, 'promise'>;
      const promise = new Promise<T>((resolve, reject) => {
        fns = { resolve, reject };
      });
      this._promise = { ...fns!, promise };
    }

    return this._promise.promise;
  }
}
