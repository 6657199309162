function wrapReader(
  r: ReadableStreamDefaultReader<Uint8Array>,
  count: (n: number) => void,
): ReadableStreamDefaultReader<Uint8Array> {
  return new Proxy(r, {
    get(target: ReadableStreamDefaultReader<Uint8Array>, p: PropertyKey, receiver: any): any {
      if (p === 'read') {
        return async (): Promise<ReadableStreamReadResult<Uint8Array>> => {
          const result = await target.read();
          count(result.value?.length || 0);
          return result;
        };
      }

      return Reflect.get(target, p, receiver);
    },
  });
}

export const countSymbol = Symbol('count');

export function wrapReadableStream(
  r: ReadableStream<Uint8Array>,
  oncount: (n: number) => void,
): ReadableStream<Uint8Array> & { readonly [countSymbol]: number } {
  let count = 0;
  return new Proxy(r, {
    get(target: ReadableStream<Uint8Array>, p: PropertyKey, receiver: any): any {
      switch (p) {
        case countSymbol:
          return count;

        case 'getReader':
          return (): ReadableStreamDefaultReader<Uint8Array> => {
            return wrapReader(target.getReader(), (n: number): void => {
              count += n;
              oncount(count);
            });
          };

        default:
          return Reflect.get(target, p, receiver);
      }
    },
  }) as any;
}
