import { wrapReadableStream } from './stream';

export type ProgressHandler = (progress: number, total: number) => void;

interface XHRFetchOptions {
  method: string;
  headers: Record<string, string>;
  body: Exclude<BodyInit, ReadableStream>;
  signal: AbortSignal;
  onuploadprogress?: ProgressHandler;
}

interface ResponseLite {
  ok: boolean;
  status: number;
  statusText: string;
  headers: Headers;
  body: () => Promise<string>;
}

async function xhrFetch(
  url: string,
  { method, headers, body, onuploadprogress, signal }: XHRFetchOptions,
): Promise<ResponseLite> {
  const xhr = new XMLHttpRequest();
  xhr.open(method, url, true);
  for (const [k, v] of Object.entries(headers)) {
    xhr.setRequestHeader(k, v);
  }

  xhr.upload.onprogress = (event: ProgressEvent) => onuploadprogress?.(event.loaded, event.total);

  signal?.addEventListener('abort', () => xhr.abort());

  const responseBody = new Promise<string>((resolve, reject) => {
    signal?.addEventListener('abort', () => reject(new DOMException('AbortError', 'The operation was aborted.')));
    xhr.addEventListener('error', ({ error }: any) => reject(error || new Error('error fetching body')));
    xhr.addEventListener('readystatechange', () => {
      if (xhr.readyState < XMLHttpRequest.DONE) {
        return;
      }

      resolve(xhr.responseText);
    });
  });

  return new Promise<ResponseLite>((resolve, reject) => {
    signal?.addEventListener('abort', () => reject(new DOMException('AbortError', 'The operation was aborted.')));
    xhr.addEventListener('error', ({ error }: any) => reject(error || new Error('error uploading')));
    let resolved = false;
    const resolveResponse = () => {
      const headers = new Headers();
      for (const line of xhr.getAllResponseHeaders().split('\r\n')) {
        const [k, v] = line.split(':', 2);
        if (!k || k.toLowerCase() === 'content-length') {
          continue;
        }

        headers.set(k, v.trim());
      }

      resolve({
        ok: 200 <= xhr.status && xhr.status <= 299,
        status: xhr.status,
        statusText: xhr.statusText,
        headers,
        body: () => responseBody,
      });
    };
    xhr.addEventListener('readystatechange', () => {
      if (xhr.readyState < XMLHttpRequest.HEADERS_RECEIVED || resolved) {
        return;
      }

      resolveResponse();
    });
    xhr.send(body);
  });
}

export interface SmartFetchOptions extends Omit<XHRFetchOptions, 'body'> {
  body: BodyInit;
}

function isXHRFetchOptions(options: SmartFetchOptions): options is XHRFetchOptions {
  return !(options.body instanceof ReadableStream);
}

function readableStreamWithUploadProgress(
  s: ReadableStream<Uint8Array>,
  onuploadprogress: Exclude<XHRFetchOptions['onuploadprogress'], undefined>,
): ReadableStream<Uint8Array> {
  let prevPosition = 0;
  return wrapReadableStream(s, function(newPosition: number) {
    if (prevPosition > 0) {
      onuploadprogress(prevPosition, -1);
    }

    prevPosition = newPosition;
  });
}

export default async function smartFetch(url: string, options: SmartFetchOptions): Promise<ResponseLite> {
  if (isXHRFetchOptions(options)) {
    return xhrFetch(url, options);
  }

  let { body, onuploadprogress } = options;
  if (body instanceof ReadableStream && onuploadprogress) {
    body = readableStreamWithUploadProgress(body, onuploadprogress);
  }

  const res = await fetch(url, { ...options, body });
  return {
    ok: res.ok,
    status: res.status,
    statusText: res.statusText,
    headers: res.headers,
    body: () => res.text(),
  };
}
