interface BodyResult {
  body: BodyInit;
  size?: number;
}

export interface BodySeekerLike {
  seekForward(n: number): void | Promise<void>;
  body(limit?: number): BodyResult | Promise<BodyResult>;
}

function createSlicableBodySeeker(body: ArrayBuffer | Blob): BodySeekerLike {
  return {
    seekForward(n: number): void {
      if (n <= 0) {
        return;
      }

      body = body.slice(n);
    },
    body(limit?: number): BodyResult {
      return {
        body: body.slice(0, limit || undefined),
        size: limit ? limit : body instanceof ArrayBuffer ? body.byteLength : body.size,
      };
    },
  };
}

// function createReadableStreamBodySeeker(body: ReadableStream<Uint8Array>): BodySeekerLike {
//   let firstSeek = true;
//   return {
//     seekForward(n: number): Promise<void> | void {
//       if (!firstSeek) {
//         return;
//       }

//       firstSeek = false;

//       return (async (): Promise<void> => {
//         const reader = body.getReader({ mode: 'byob' });
//         try {
//           let remaining = n;
//           while (remaining > 0) {
//             const result = await reader.read(new Uint8Array(remaining));
//             if (result.done) {
//               return;
//             }

//             remaining -= result.value.byteLength;
//           }
//         } finally {
//           reader.releaseLock();
//         }
//       })();
//     },
//     body(): BodyInit {
//       return body;
//     },
//   };
// }

export class BodySeeker implements BodySeekerLike {
  private pos = 0;

  constructor(private readonly open: (n: number, limit?: number) => BodyResult | Promise<BodyResult>) {}

  seekForward(n: number): void | Promise<void> {
    this.pos += n;
  }

  body(limit?: number): BodyResult | Promise<BodyResult> {
    return this.open(this.pos, limit);
  }
}

export async function createBodySeeker(body: Exclude<BodyInit, ReadableStream> | BodySeeker): Promise<BodySeekerLike> {
  if (body instanceof BodySeeker) {
    return body;
  }

  if (body instanceof FormData || body instanceof URLSearchParams || typeof body === 'string') {
    body = await new Response(body).arrayBuffer();
  }

  if (ArrayBuffer.isView(body)) {
    body = body.buffer;
  }

  return createSlicableBodySeeker(body);
}
