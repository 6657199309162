import { createBackoffFunction, BackoffOptions, BackoffFunction } from '@insights-gaming/backoff';

import gcs from './gcs';
import { PromiseSource } from './promise';
import { BodySeeker, createBodySeeker } from './seeker';
import tus from './tus';
import { UploadInterface, UploadParams, UploadResponse } from './types';

export interface UploadStartedEvent {
  type: 'started';
  position: number;
  length: number;
}

export interface UploadPausedEvent {
  type: 'paused';
}

export interface UploadProgressEvent {
  type: 'progress';
  position: number;
  length: number;
}

export interface UploadChunkEvent {
  type: 'chunk';
  position: number;
  length: number;
}

export interface UploadCompletedEvent {
  type: 'completed';
}

export interface UploadFailedEvent {
  type: 'failed';
  reason: Error;
}

export interface UploadAbortedEvent {
  type: 'aborted';
}

export type UploadEvent =
  | UploadStartedEvent
  | UploadPausedEvent
  | UploadProgressEvent
  | UploadChunkEvent
  | UploadCompletedEvent
  | UploadFailedEvent
  | UploadAbortedEvent;

export interface Upload extends AsyncIterableIterator<UploadEvent> {
  start(): void;
  pause(): void;
  abort(): void;
}

export interface UploadOptions {
  size: number;
  name: string;
  type: string;
  contents: Exclude<BodyInit, ReadableStream> | BodySeeker;
  chunkSize?: number;
  maxRetries?: number;
  retry?: BackoffOptions | BackoffFunction;
}

enum UploadState {
  DEFAULT,
  PAUSED,
  STARTED,
  DONE,
  FAILED,
  ABORTED,
}

function isEndState(state: UploadState): boolean {
  return state === UploadState.DONE || state === UploadState.ABORTED || state === UploadState.FAILED;
}

async function uploadChunk(
  uploader: UploadInterface,
  url: string,
  params: UploadParams,
): Promise<UploadResponse | void> {
  try {
    return await uploader.uploadChunk(url, params);
  } catch (err) {
    if (params.signal?.aborted) {
      return;
    }

    throw err;
  }
}

type CallFunction = <F extends (...args: any[]) => any>(f: F, ...args: Parameters<F>) => Promise<ReturnType<F>>;

function createCallFunction(backoff: (attempt: number) => number, maxRetries: number): CallFunction {
  return async <F extends (...args: any[]) => any>(f: F, ...args: Parameters<F>): Promise<ReturnType<F>> => {
    for (let i = 0; ; i++) {
      try {
        return await f(...args);
      } catch (err) {
        if (i >= maxRetries) {
          throw err;
        }
      }

      await new Promise(resolve => setTimeout(resolve, backoff(i)));
    }
  };
}

function buildUpload(
  uploader: UploadInterface,
  url: string,
  { contents, name, size, chunkSize, type, call }: Omit<UploadOptions, 'maxRetries' | 'retry'> & { call: CallFunction },
): Upload {
  const bp = new PromiseSource<UploadEvent>();

  let state = UploadState.DEFAULT;
  let abortController: AbortController | undefined;
  async function start(): Promise<void> {
    if (abortController) {
      return;
    }

    abortController = new AbortController();
    try {
      const seeker = await createBodySeeker(contents);

      let seekPosition = 0;
      function onuploadprogress(progress: number): void {
        bp.resolve({ type: 'progress', position: progress + seekPosition, length: size });
      }

      let currentPosition: number | undefined;
      while (!abortController.signal.aborted && !isEndState(state)) {
        if (currentPosition === undefined) {
          const position = await call(uploader.position, url, { name, type, size });
          if (state === UploadState.DEFAULT || state === UploadState.PAUSED) {
            bp.resolve({ type: 'started', position, length: size });
            state = UploadState.STARTED;
          } else {
            bp.resolve({ type: 'chunk', position, length: size });
          }

          currentPosition = position;
        }

        if (currentPosition >= size) {
          state = UploadState.DONE;
          bp.resolve({ type: 'completed' });
          break;
        }

        if (currentPosition > 0) {
          await seeker.seekForward(currentPosition - seekPosition);
          seekPosition = currentPosition;
        }

        const { size: bodySize, body } = await seeker.body(chunkSize);

        const resolvedChunkSize = chunkSize || bodySize || size - currentPosition;
        const res = await call(uploadChunk, uploader, url, {
          body,
          length: size,
          chunkSize: resolvedChunkSize,
          offset: currentPosition,
          onuploadprogress,
          signal: abortController.signal,
        });
        if (!res) {
          bp.resolve({ type: 'aborted' });
          break;
        }

        if (res.ok) {
          currentPosition = res.position;
        } else if (res.status < 500 || 599 < res.status) {
          // not a server error
          throw new Error(`upload failed: ${res.status} - ${await res.body()}`);
        }
      }
    } catch (err) {
      state = UploadState.FAILED;
      bp.reject(err);
    } finally {
      abortController = undefined;
    }
  }

  return {
    [Symbol.asyncIterator]() {
      return this;
    },
    async next(): Promise<IteratorResult<UploadEvent, void>> {
      const result = bp.result();
      if (result) {
        if (result.status === 'rejected') {
          throw result.reason;
        }

        return { value: result.value, done: false };
      }

      if (isEndState(state)) {
        return { value: undefined, done: true };
      }

      return {
        value: await bp.next(),
        done: false,
      };
    },
    start(): void {
      start();
    },
    pause(): void {
      if (abortController) {
        state = UploadState.PAUSED;
        abortController.abort();
        bp.resolve({ type: 'paused' });
      }
    },
    async abort(): Promise<void> {
      state = UploadState.ABORTED;
      abortController?.abort();
      await call(uploader.terminate, url);
      bp.resolve({ type: 'aborted' });
    },
  };
}

const uploaders: UploadInterface[] = [gcs, tus];

export default async function createUpload(
  url: string,
  { retry, maxRetries, ...options }: UploadOptions,
): Promise<[string, Upload]> {
  const uploader = uploaders.find(uploader => (uploader.match ? uploader.match(url) : true));
  if (!uploader) {
    throw new Error('uploader not found');
  }

  if (typeof retry !== 'function') {
    retry = createBackoffFunction(retry);
  }

  const call = createCallFunction(retry, typeof maxRetries === 'undefined' ? 5 : maxRetries < 0 ? Infinity : maxRetries);
  url = await call(uploader.initialize, url, options);

  return [url, buildUpload(uploader, url, { ...options, call })];
}

export { BodySeeker } from './seeker';
