import smartFetch from './fetch';
import { ContentInfo, UploadInterface, UploadParams, UploadResponse } from './types';

function match(url: string): boolean {
  return url.startsWith('https://storage.googleapis.com/');
}

function isUploadSessionURL(url: string): boolean {
  return !!new URL(url).searchParams.get('upload_id');
}

async function initialize(url: string, { type }: ContentInfo): Promise<string> {
  if (isUploadSessionURL(url)) {
    return url;
  }

  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'x-goog-resumable': 'start',
      'content-type': type,
      'content-length': '0',
    },
  });

  const loc = res.headers.get('location');
  if (!res.ok || !loc) {
    throw new Error('failed to initialize upload');
  }

  return loc;
}

function parseContentRange(range: string | null): number {
  const upper = +(range?.match(/^bytes=0-(\d+)$/)?.[1] || '');
  if (!isNaN(upper)) {
    return upper;
  }

  throw new Error('invalid range');
}

async function position(url: string, { size }: ContentInfo): Promise<number> {
  const res = await fetch(url, {
    method: 'PUT',
    headers: {
      'content-range': `bytes */${size}`,
    },
  });
  if (res.ok) {
    // upload complete
    return size;
  }

  if (res.status === 308) {
    try {
      return parseContentRange(res.headers.get('range'));
    } catch {}
  }

  throw new Error('unexpected response from position request');
}

async function uploadChunk(
  url: string,
  { body, offset, chunkSize, length, onuploadprogress, signal }: UploadParams,
): Promise<UploadResponse> {
  const headers: Record<string, string> = {
    'content-length': chunkSize.toString(),
  };
  if (offset > 0) {
    headers['content-range'] = `bytes ${offset}-${offset + chunkSize - 1}/${length}`;
  }

  const res = await smartFetch(url, {
    method: 'PUT',
    headers,
    body,
    onuploadprogress,
    signal,
  });

  const stored = +(res.headers.get('x-goog-stored-content-length') || '');
  return {
    ...res,
    position: stored ? offset + stored : undefined,
  };
}

async function terminate(url: string): Promise<void> {
  await fetch(url, { method: 'DELETE', headers: { 'Content-Length': '0' } });
}

export default {
  match,
  initialize,
  position,
  uploadChunk,
  terminate,
} as UploadInterface;
