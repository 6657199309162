import smartFetch from './fetch';
import { ContentInfo, UploadInterface, UploadParams, UploadResponse } from './types';

interface MinimalResponse {
  ok: boolean;
  headers: Headers;
  status: number;
  statusText: string;
}

function verifyTusResponse<T extends MinimalResponse>(res: T): T {
  if (!res.ok) {
    throw new Error(`unexpected status code: ${res.status} - ${res.statusText}`);
  }

  if (res.headers.get('Tus-Resumable') !== '1.0.0') {
    throw new Error('missing tus header');
  }

  return res;
}

interface ServerConfig {
  versions: string[];
  maxSize: number;
  extensions: string[];
}

async function queryServerConfig(url: string): Promise<ServerConfig> {
  const res = verifyTusResponse(await fetch(url, { method: 'OPTIONS' }));
  return {
    versions: (res.headers.get('Tus-Version') || '').split(','),
    maxSize: +(res.headers.get('Tus-Max-Size') || ''),
    extensions: (res.headers.get('Tus-Extension') || '').split(','),
  };
}

function withTusHeader(headers: Record<string, string> = {}): Record<string, string> {
  return { ...headers, 'Tus-Resumable': '1.0.0' };
}

async function createUpload(url: string, length: number, metadata: Record<string, string>): Promise<string> {
  const headers: Record<string, string> = {
    'Content-Length': '0',
    'Upload-Length': length.toString(),
  };

  const metadataEntries = Object.entries(metadata);
  if (metadataEntries.length > 0) {
    headers['Upload-Metadata'] = metadataEntries
      .map(([key, value]) => (value ? key + ' ' + btoa(value) : value))
      .join(',');
  }

  const res = verifyTusResponse(await fetch(url, { method: 'POST', headers: withTusHeader(headers) }));
  const location = res.headers.get('Location');
  if (!location) {
    throw new Error('url missing from creation response');
  }

  return location;
}

async function initialize(url: string, { name, size }: ContentInfo): Promise<string> {
  const config = await queryServerConfig(url);
  if (!config.versions.includes('1.0.0')) {
    throw new Error('version mismatch with server');
  }

  if (config.maxSize < size) {
    throw new Error('file too big');
  }

  if (config.extensions.includes('creation')) {
    url = await createUpload(url, size, { filename: name });
  }

  return url;
}

async function position(url: string): Promise<number> {
  const res = verifyTusResponse(await fetch(url, { method: 'HEAD', headers: withTusHeader() }));
  const offset = res.headers.get('Upload-Offset');
  if (!offset) {
    throw new Error('offset missing from creation response');
  }

  const position = +offset;
  if (isNaN(position) || position < 0) {
    throw new Error('invalid offset from creation response');
  }

  return position;
}

async function uploadChunk(
  url: string,
  { body, offset, chunkSize, onuploadprogress, signal }: UploadParams,
): Promise<UploadResponse> {
  const res = verifyTusResponse(
    await smartFetch(url, {
      method: 'PATCH',
      headers: withTusHeader({
        'Content-Type': 'application/offset+octet-stream',
        'Content-Length': chunkSize.toString(),
        'Upload-Offset': offset.toString(),
      }),
      body,
      onuploadprogress,
      signal,
    }),
  );

  return {
    ...res,
    position: +(res.headers.get('Upload-Offset') || '') || undefined,
  };
}

async function terminate(url: string): Promise<void> {
  const config = await queryServerConfig(url);
  if (!config.extensions.includes('termination')) {
    return;
  }

  verifyTusResponse(
    await fetch(url, {
      method: 'DELETE',
      headers: withTusHeader({ 'Content-Length': '0' }),
    }),
  );
}

export default {
  initialize,
  position,
  uploadChunk,
  terminate,
} as UploadInterface;
